import './css/Footer.css';

function Footer() {
    return (
        <footer>
            Foota!
        </footer>
    );
}

export default Footer;