function NotFound() {
    return (
        <section className="not-found-error">
            <h2>???</h2>
            <p><strong>Sorry</strong> but I couldn't find what you were looking for :'(</p>
        </section>
    );
}

export default NotFound;
